<template>
  <header class="header">
    <div class="container">
      <div class="header-inner">
        <router-link class="logo" to="/">
          <div class="logo__icon"></div>
          <!--div class="logo__text">
            Электронная
            <br />подпись онлайн
          </div-->
                </router-link>
                <nav>
                    <ul>
                        <li>
                            <router-link to="/">Заявки</router-link>
                        </li>
                        <li>
                            <router-link to="/preapplication/list"
                                ><span
                                    ><span>Предзаявки</span
                                    ><span
                                        v-if="newPreAppNum != 0"
                                        class="badge badge-light"
                                        >{{ newPreAppNum }}</span
                                    ></span
                                ></router-link
                            >
                        </li>
                        <li v-if="isRevokesAllowed">
                            <router-link to="/revoke/list">На отзыв</router-link>
                        </li>
                        <li v-if="isCertificatesVisible">
                            <router-link to="/certificate/list">Сертификаты</router-link>
                        </li>
                        <li>
                            <router-link to="/archive">Архив</router-link>
                        </li>
                        <li>
                            <router-link to="/profile">Профиль</router-link>
                        </li>
                        <li>
                            <router-link to="/logout">Выйти</router-link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import { getNameId } from '@/services/api/fetch';
import { SET_IS_REVOKES_ALLOWED } from '@/store/modules/revoke-list/revoke-list.mutations';
import { getRole } from '@/services/api/fetch';
import ROLES from "@/config/roles";

export default Vue.extend({
    name: 'Header',

    computed: {
        ...mapState('revokeListState', ['isRevokesAllowed']),
        ...mapGetters('preapplicationList', ['newPreAppNum']),

        isCertificatesVisible() {
            const role = getRole();
            return role in ROLES && [ROLES.ChiefManager].includes(role);
        },
    },

    methods: {
        ...mapActions('preapplicationList', ['getNewPreAppNum']),
    },

    async mounted() {
        this.getNewPreAppNum();
    }
});
</script>

<style lang="scss">
.header {
    &-inner {
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    nav {
        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            list-style-type: none;
            li {
                a {
                    font-size: 14px;
                    color: #000;
                    text-decoration: none;
                }
                & + li {
                    margin-left: 60px;
                }
            }
            .badge {
                position: relative;
                top: -10px;
                left: -1px;
                border-radius: 50%;
                padding: 3px 9px;
                min-width: 16px;
                //background: #7a6afa; фиолетовый брендовый
                background: #ff6a6a;
                color: #fff;
            }
        }
    }
}
</style>
