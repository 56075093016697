var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-top":"10px"}},[_c('div',{ref:"select",class:[
      'form-item',
      ("form-item-" + _vm.stype),
      {
        valid: _vm.valid,
        invalid: _vm.isValidation(_vm.$props)
      }
    ]},[(_vm.label !== undefined)?_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{class:[
        'form-item-select',
        {
          'select-is-focus': _vm.isFocus,
          'select-is-open': _vm.isOpen,
          'select-is-disabled': _vm.disabled
        },
        _vm.className ],attrs:{"tabindex":"-1"},on:{"focus":_vm.focusHandler,"blur":function (evt) {
          _vm.focusHandler(evt);
          _vm.$emit('blur', { target: { name: _vm.name, value: _vm.innerValue } });
        }}},[_c('div',{staticClass:"form-item-select__control",on:{"click":_vm.toggleHandler}},[_c('div',{staticClass:"form-item-select__container"},[(_vm.innerLabel)?_c('div',{staticClass:"form-item-select__value"},[_vm._v(" "+_vm._s(_vm.innerLabel)+" ")]):_vm._e(),(_vm.placeholder !== undefined && !_vm.innerLabel)?_c('div',{staticClass:"form-item-select__placeholder"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]):_vm._e(),_c('div',{staticClass:"form-item-select__input"},[_c('input',{attrs:{"id":_vm.id,"autocomplete":"off","tabindex":"0","aria-autocomplete":"list","auto-correct":"off","name":_vm.name},domProps:{"value":_vm.innerValue}})])]),_vm._m(0)]),(_vm.hasSearch && _vm.isOpen && _vm.filteredOptions !== null)?_c('input',{staticClass:"form-item-select__searchInOptions",attrs:{"name":"searchInOptions","placeholder":"Поиск по названию.."},domProps:{"value":_vm.searchInOptions},on:{"input":function($event){$event.preventDefault();return (function (evt) { return _vm.setSearchInOptions(evt.target.value); }).apply(null, arguments)}}}):_vm._e(),(!_vm.valid && _vm.invalidityInfo)?_c('span',{staticClass:"input-wrap__invalidity-info"},[_vm._v(_vm._s(_vm.invalidityInfo))]):_vm._e(),_c('transition',{attrs:{"name":("form-item-select__menu_" + _vm.optionsPosition)}},[(_vm.isOpen && _vm.filteredOptions !== null)?_c('div',{class:[
            'form-item-select__menu',
            ("form-item-select__menu_" + _vm.optionsPosition),
            {'hasSearch': _vm.hasSearch} ],style:({
            'max-height': _vm.maxHeightMenu
          })},_vm._l((_vm.filteredOptions),function(option){return _c('div',{key:option.value,staticClass:"form-item-select__menu-option",on:{"click":function($event){return _vm.setValue(option)}}},[(Array.isArray(option.label))?_c('div',_vm._l((option.label),function(str,index){return _c('div',{key:index},[_vm._v(_vm._s(str))])}),0):_c('div',{class:['form-item-select__menu-option__content', {'deletable': option.deletable}]},[_c('div',[_vm._v(_vm._s(option.label))]),(option.deletable && _vm.deleteOptionHandler)?_c('button',{on:{"click":function($event){$event.preventDefault();return (function (evt) {
                  evt.stopPropagation();
                  _vm.deleteOptionHandler(option.value);
                }).apply(null, arguments)}}},[_c('IconBase',{attrs:{"iconName":"bucket","width":"15","height":"15","viewBox":"0 0 15 15"}},[_c('Bucket')],1)],1):_vm._e()])])}),0):_vm._e()])],1)]),_c('div',{staticClass:"form-item form-item-max"},[_c('div',{staticStyle:{"width":"100%"}},_vm._l((_vm.errors),function(error,index){return _c('div',{key:index,staticClass:"error-massage"},[_vm._v(_vm._s(error))])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-item-select__indicators"},[_c('span')])}]

export { render, staticRenderFns }